/* eslint-disable camelcase */
import React, { FC, useState, useMemo, useRef, useEffect } from 'react';
import {
  Filter,
  ParagraphWithGradientTop,
  Pagination,
  PageHeading,
  PageLayout,
  BlogFeed,
} from 'components';
import { graphql } from 'gatsby';
import { BlogListPageTypes } from 'types';
import { useLocation } from '@reach/router';

const PageSize = 8;
const Blog: FC<BlogListPageTypes> = ({ data }: any) => {
  const [BlogsFilteredValue, setBlogsFilteredValue] = useState(
    data.Blogs.edges
  );
  const [currentPage, setCurrentPage] = useState(1);
  const BlogFilter = data.BlogFilters.edges.map(blog => blog.node.title);
  const RecipeListData = useMemo(() => {
    if (BlogsFilteredValue.length < PageSize) {
      setCurrentPage(1);
    }
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return BlogsFilteredValue.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, BlogsFilteredValue]);
  const titleref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleonClick = (page: any) => {
    setCurrentPage(page);
    window.scrollTo(0, titleref.current.offsetTop);
  };
  const { search } = useLocation();
  const filtername = new URLSearchParams(search).get('filter');

  useEffect(() => {
    if (filtername) {
      const FilterdValue = data.Blogs.edges.filter((filterItem: any) => {
        const tagValues = filterItem.node.article_tags.map(
          (tag: any) => tag.title
        );
        return tagValues.includes(filtername);
      });
      setBlogsFilteredValue(FilterdValue);
    }
  }, []);

  const {
    BlogPage: {
      pageTheme,
      description,
      title,
      banner,
      seo: { metaDescription, metaTitle, shareImage },
    },
  } = data;
  const { learningBanner } = data.Learninghub;
  return (
    <PageLayout
      title="Blog"
      metaTitle={metaTitle ?? title}
      description={metaDescription}
      image={shareImage?.localFile?.publicURL}
      className={`${pageTheme}`}
      hasContainerClass
      bannerTitle={banner?.title || learningBanner.title}
      bannerTagline={banner?.tagline || learningBanner.tagline}
      bannerImage={banner?.image || learningBanner.image}
      bannerButton={banner?.ctaButton || learningBanner.ctaButton}
      bannerPlaceholder={
        banner?.postcodePlaceholder || learningBanner.postcodePlaceholder
      }
    >
      <PageHeading title={title} level={1} />
      <Filter
        Filters={BlogFilter}
        setFilteredList={setBlogsFilteredValue}
        ListToFilter={data.Blogs.edges}
        FilterCategory="article_tags"
        setCurrentPage={setCurrentPage}
        titleref={titleref}
        filtervalue={filtername || ''}
      />
      <ParagraphWithGradientTop>{description}</ParagraphWithGradientTop>
      <section className="blog_list">
        <div className="blog_feed">
          {RecipeListData.map((item: any, index: number) => {
            return (
              <BlogFeed
                Blogs={item.node}
                index={index}
                key={item.node.id}
                characterLimit={140}
                blogUrl={`${data.Learninghub.slug}/${data.strapiBlogPage.slug}`}
              />
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={BlogsFilteredValue.length}
          pageSize={PageSize}
          onPageChange={handleonClick}
        />
      </section>
    </PageLayout>
  );
};

export default Blog;
export const query = graphql`
  query BlogPageQuery {
    Learninghub: strapiLearningHubPage {
      slug
      title
      learningBanner: banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    BlogPage: strapiBlogPage {
      title
      description: pageDescription
      pageTheme
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    Blogs: allStrapiArticle(sort: { fields: strapiId, order: DESC }) {
      edges {
        node {
          id
          title
          description
          slug
          content
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          article_tags {
            title
          }
          video {
            localFile {
              publicURL
            }
          }
          imageLink
        }
      }
    }
    BlogFilters: allStrapiArticleTag {
      edges {
        node {
          title
        }
      }
    }
    strapiBlogPage {
      slug
    }
  }
`;
